<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ $t('销售订单.订单详情') }}</b>
    </a-divider>
    <!--订单状态步骤条-->
    <a-steps v-if="this.record.id" :current="this.record.orderStatus" size="small">
      <a-step v-for="(item, index) in this.record.operateLogList" :key="index">
        <template slot="title">
          <div style=" font-size: 18px">{{ item.statusEvent }}</div>
          <div>{{ item.orderStatusName }}</div>
          <div v-if="item.remark">{{ item.remark }}</div>
          <div>{{ item.createTime }}</div>
        </template>
      </a-step>
    </a-steps>

    <div class="order-info">
      <table>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.订单信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">{{ $t('销售订单.订单编号') }}：</td>
                  <td class="data">
                    {{ this.record.id }}
                  </td>
                  <td class="first">{{ $t('销售订单.订单状态') }}：</td>
                  <td class="data">
                    {{ this.record.orderStatusName }}
                  </td>
                  <td class="first">{{ $t('销售订单.下单时间') }}：</td>
                  <td class="data">
                    {{ this.record.createTime }}
                  </td>
                </tr>
                <tr>
                  <td class="first">{{ $t('销售订单.订单来源') }}：</td>
                  <td class="data">
                    {{ this.record.sourceName }}
                  </td>
                  <td class="first">{{ $t('销售订单.买家名称') }}：</td>
                  <td class="data">
                    {{ this.record.buyerName }}
                  </td>
                  <td class="first">{{ $t('销售订单.支付时间') }}：</td>
                  <td class="data">
                    {{ this.record.payTime }}
                  </td>
                </tr>
                <tr>
                  <td class="first">{{ $t('销售订单.订单金额') }}：</td>
                  <td class="data">
                    {{ this.record.orderAmount }}
                  </td>
                  <td class="first">{{ $t('销售订单.商品金额') }}：</td>
                  <td class="data">
                    {{ this.record.goodsAmount }}
                  </td>
                  <td class="first">{{ $t('销售订单.商品数量') }}：</td>
                  <td class="data">
                    {{ this.record.goodsCount }}
                  </td>
                </tr>
                <tr>
                  <td class="first">{{ $t('销售订单.实付金额') }}：</td>
                  <td class="data">
                    {{ this.record.payAmount }}
                  </td>
                  <td class="first">{{ $t('销售订单.支付方式') }}：</td>
                  <td class="data">
                    {{ this.record.payTypeName }}
                  </td>
                  <td class="first">{{ $t('销售订单.用户备注') }}：</td>
                  <td colspan="7">
                    {{ this.record.userMessage }}
                  </td>
                </tr>
                <tr>
                  <td class="first">{{ $t('销售订单.预约日期') }}：</td>
                  <td class="data">
                    {{ this.record.dateTime }}
                  </td>
                  <td class="first">{{ $t('销售订单.预约开始时间') }}：</td>
                  <td class="data">
                    {{ this.record.startTime }}
                  </td>
                  <td class="first">{{ $t('销售订单.预约结束时间') }}：</td>
                  <td colspan="7">
                    {{ this.record.endTime }}
                  </td>
                </tr>
                <tr>
                  <td class="first">{{ $t('销售订单.是否需要预约') }}：</td>
                  <td class="data">
                    {{ this.record.reservationName }}
                  </td>
                  <td class="first">{{ $t('销售订单.售卖结束时间') }}：</td>
                  <td class="data">
                    {{ this.record.saleEndTime }}
                  </td>
                  <td class="first">{{ $t('销售订单.核销码') }}：</td>
                  <td colspan="7">
                    {{ this.record.code }}
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.商品信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <a-table :bordered="false" :pagination="false" :columns="goodsColumns" rowKey="skuId"
                       :data-source="record.orderItemList">
                <template slot="picture" slot-scope="text, record">
                  <div>
                    <img v-if="record.picture" :src="record.picture" style="width:60px;height:62px;"
                         @click="handleRecordPreview(record.picture)" alt="none"/>
                  </div>
                </template>
              </a-table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.套餐信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <a-table :bordered="false" :pagination="false" :columns="thaliDetails" rowKey="skuId"
                       :data-source="record.thaliSysDetails">
              </a-table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.人员信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <a-table :bordered="false" :pagination="false" :columns="personDetails" rowKey="skuId"
                       :data-source="record.orders">
              </a-table>
            </a-card>
          </td>
        </tr>
        <tr v-if="record.orderActivityDetail!=null">
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.活动信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">{{ $t('活动记录.活动编号') }}：</td>
                  <td class="data">
                    {{ this.record.orderActivityDetail.id }}
                  </td>
                  <td class="first">{{ $t('活动记录.活动名称') }}：</td>
                  <td class="data">
                    {{ this.record.orderActivityDetail.activityName }}
                  </td>
                  <td class="first">{{ $t('活动记录.活动封面图') }}：</td>
                  <td class="data">
                    <div>
                      <img v-if="record.orderActivityDetail.coverPic" :src="record.orderActivityDetail.coverPic"
                           style="width:60px;height:62px;"
                           @click="handleRecordPreview(record.orderActivityDetail.coverPic)" alt="none"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="first">{{ $t('活动记录.开始时间') }}：</td>
                  <td class="data">
                    {{ this.record.orderActivityDetail.startTime }}
                  </td>
                  <td class="first">{{ $t('活动记录.结束时间') }}：</td>
                  <td class="data">
                    {{ this.record.orderActivityDetail.endTime }}
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>

        <!--        <tr>
                  <td style="vertical-align: top;background-color:#fbfbfb;">
                    <a-card  :bordered="false" :title="$t('销售订单.收货地址信息')"
                             :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                             :bodyStyle="{'background-color':'#fbfbfb'}">
                      <table class="info-table">
                        <tr>
                          <td class="first">{{$t('销售订单.收货人姓名')}}：</td>
                          <td class="data">
                            {{ this.record.orderAddressDetail.realName}}
                          </td>
                          <td class="first">{{$t('销售订单.性别')}}：</td>
                          <td class="data">
                            {{ this.record.orderAddressDetail.sexName}}
                          </td>
                          <td class="first">{{$t('销售订单.手机号')}}：</td>
                          <td class="data">
                            {{this.record.orderAddressDetail.mobile}}
                          </td>
                        </tr>
                        <tr>
                          <td class="first">{{$t('销售订单.邮箱')}}：</td>
                          <td class="data">
                            {{ this.record.orderAddressDetail.email}}
                          </td>
                          <td class="first">{{$t('销售订单.地址类型')}}：</td>
                          <td class="data">
                            {{ this.record.orderAddressDetail.addressTypeName}}
                          </td>
                          <td class="first">{{$t('销售订单.地区')}}：</td>
                          <td class="data">
                            {{this.record.orderAddressDetail.nationName}}
                          </td>
                        </tr>
                        <tr>
                          <td class="first">{{$t('销售订单.详细地址')}}：</td>
                          <td  colspan="5">
                            {{ this.record.orderAddressDetail.areap + this.record.orderAddressDetail.areac + this.record.orderAddressDetail.areax+this.record.orderAddressDetail.detailedAddress}}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a-button type="primary" v-if="record.orderStatus===2030|| record.orderStatus===2050"  @click="$refs.logisticDetail.handleDetail(record.id)">
                              {{$t('销售订单.物流详情')}}
                            </a-button>
                          </td>
                        </tr>
                      </table>
                    </a-card>
                  </td>
                </tr>-->

      </table>
      <logistic-detail
        ref="logisticDetail"
      />
      <delivery-form
        ref="deliveryForm"
        @ok="handleDetail(this.record.id)"
      />
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
    </div>
    <div class="bottom-control">
      <a-space>
<!--        <a-button type="primary" v-hasPermi="['order:sell:delivery']" v-if="this.record.orderStatus===2020"-->
<!--                  @click="$refs.deliveryForm.handleDelivery(record.id)">-->
<!--          {{ $t('销售订单.发货') }}-->
<!--        </a-button>-->
        <a-button type="dashed" @click="cancel">
          {{ $t('通用.按钮.关闭') }}
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import {getSellOffline} from '@/api/order/sell'
import {mapGetters} from 'vuex'
import LogisticDetail from "./LogisticDetail";
import CustomDictTag from "@/components/DictCustomTag";
import DeliveryForm from "./DeliveryForm";


export default {
  name: 'detailForm',
  props: {},
  components: {
    CustomDictTag,
    LogisticDetail,
    DeliveryForm,
  },
  data() {
    return {
      record: {
        id: null,
        orderAddressDetail: {},
        orderActivityDetail: {},
      },
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      // 表单参数
      form: {
        id: null,
        outTradeNo: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      goodsColumns: [
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          align: 'center',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          scopedSlots: {customRender: 'picture'},
          align: 'center'
        },
        {
          title: this.$t('销售订单.商品数量'),
          dataIndex: 'goodsCount',
          align: 'center'
        },
        {
          title: this.$t('商品.商品价格'),
          dataIndex: 'price',
          align: 'center'
        },
        {
          title: this.$t('销售订单.实付单价'),
          dataIndex: 'payPrice',
          align: 'center'
        },
      ],
      thaliDetails: [
        {
          title: this.$t('线下店铺套餐详情.套餐名称'),
          dataIndex: 'subtitle',
          align: 'center',
        },
        {
          title: this.$t('线下店铺套餐详情.套餐详情价格'),
          dataIndex: 'price',
          align: 'center'
        },
      ],
      personDetails: [
        {
          title: this.$t('线下店铺套餐详情.姓名'),
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: this.$t('线下店铺套餐详情.手机号'),
          dataIndex: 'mobile',
          align: 'center'
        },
      ],
      rules: {}
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        outTradeNo: null,
        remark: null,
      }
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },

    /** 修改按钮操作 */
    handleDetail(id) {
      this.reset()
      this.formType = 2
      getSellOffline({"id": id}).then(response => {
        this.record = response.data
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            payAdvert(this.form).then(response => {
              this.$message.success(
                '支付成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}


.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}

.order-info {
  border: 1px solid #e8e8e8;
  margin-top: 40px;

  .info-table {
    tr {
      height: 50px;
    }

    td {
      display: table-cell;
      vertical-align: top;
      font-size: 16px;
    }

    .first {
      width: 100px;
    }

    .data {
      width: 300px;
    }
  }
}

.detailPanel {
  dd, dt {
    display: inline-block;
    vertical-align: center;
  }

  dt img {
    margin: 0 20px 5px 0px;
  }

  dd {
    h3 {
      font-weight: bold;
    }
  }

  .trade-detail-prompt {
    margin-left: 35px;

    li {
      font-size: 13px !important;

      .dotted-node {
        padding: 0 5px;
      }
    }
  }
}

.order-money {
  .om-c1 {
    font-size: 14px;
    color: #666
  }

  .om-c2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .om-c3 {
    font-size: 16px;
    color: #DE3636;
    font-weight: bold;
  }

  td {
    text-align: right;
  }

  float: right;
  margin: 20px 40px 20px 0;
}
</style>
